import styled from 'styled-components'
import { shade } from 'polished';

export const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 120px auto;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin: 0 auto 70px;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;

    width: 60vh;

    button {
        
        height: 50px;
        background: #80F5E4;
        border-radius: 100px;
        margin: 12px;
        transition: background-color 0.2s;
        text-transform: uppercase;
        border:none;
        
        &:hover {
            background: ${shade(0.2, '#85F5E4')};
        }
    }

    @media (max-width: 768px) {
        width: 323px;
        margin-top: 25px;
    }
`;

export const Input = styled.input`
    margin-top: 16px;
    max-width: 60vh;
    height: ${props => (props.customHeight ? props.customHeight : "56px")};
    
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    
    ::placeholder {
       color: #ADB5BD;
       vertical-align: baseline;
    }
`;

export const Error = styled.span`
    display: block;
    color: #c53030;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
`;

export const Success = styled.span`
    display: block;
    
    color: #173746;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
`;

export const Box = styled.div `
    flex-direction: column;
    max-width: 413px;
    background: #EBFFFC;
    
    margin-left: 82px;

    @media (max-width: 768px) {
        flex: 1;
        margin: 0 0;
    }

    h1 {
        margin: 60px 60px 0 60px;

        font-family: Montserrat;
        max-width: 249px;
        max-height: 101px;
        font-size: 24px;
        line-height: 34px;

        color: #173746;
    }

    p {
        margin: 16px 60px;
        max-width: 280px;
        max-height: 48px;
        
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #838F8D;
    }

    ul {

        list-style: none;
        padding: 0;
        margin: 33px 60px;
        text-transform: none;

        li {
            font-family: Roboto;
            font-size: 14px;
            color: #6C757C;
            margin-top: 15px;
            
            ::before { 
                content: "●";
                line-height: 24px;
                margin-right: .7em; 
                text-align: center; 
                color: #80F5E4;
                display: inline-block; 
            }
        }
    }
`;

export const Icon = styled.div `
    position: absolute;
    margin: -40px 40px;
    
    width: 80px;
    height: 80px;

    background: #173746;

    svg {
        color: #FFF;
        position:relative;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }
`;