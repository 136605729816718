import React , { useState, FormEvent }from 'react';
import {FiRepeat} from 'react-icons/fi';

import { Container, 
         Form, 
         Input, 
         Error,
         Success,
         Box,
         Icon } from './styles';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [inputError, setInputError] = useState('');

    async function handleDoContact(event) {
        event.preventDefault();

        if (!name) {
            setInputError('Digite o seu nome');
            return;
        }

        if (!email) {
            setInputError('Digite o seu email');
            return;
        }
        if (!phone) {
            setInputError('Digite o seu Telefone');
            return;
        }
        if (!message) {
            setInputError('Digite uma menssagem');
            return;
        }

        setInputError('');
        return true;
    }

    return (
        <>  
        <Container id="contact">

            <Form 
                hasError={!!inputError} 
                // onSubmit={handleDoContact} 
                method="post"
                name = "contact-form"
                action="https://getform.io/f/25e6e378-bdb6-41e5-98f5-d149426ef751"
            >
                
                {inputError && <Error>{inputError}</Error>}

                {/* {!inputError && message && <Success>Em breve entraremos em contato</Success>} */}

                <Input
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text" 
                    name="name"
                />

                <Input
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text" 
                    name="email"
                />

                <Input
                    placeholder="Telefone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text" 
                    name="phone"
                />

                <Input
                    placeholder="Mensagem"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    customHeight='180px'
                    type="text" 
                    name="message"
                />

                <button type="submit">Agendar Demonstração</button>

            </Form>

            <Box>
                <Icon> 
                    <FiRepeat size={40} />
                </Icon>

                 <h1>Agende já uma demonstração com nossa equipe</h1>
                 <p>Conheça como nossa plataforma pode ajudar seu negócio</p>

                 <ul> 
                     <li>Contato em até 24 horas</li>
                     <li>Consultores especializados</li>
                     {/* <li>Atendimento Exclusivo</li> */}
                 </ul>
            </Box>

        </Container>
        </>
    )
}

export default Contact 